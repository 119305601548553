import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";

export default (props) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState();
  const [mostrar, setMostrar] = useState(false);
  const [data, setData] = useState([]);

  const [espera, setEspera] = useState(false);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };
  useEffect(() => {
    fetch(`https://server.lemandolosdelamoto.com/items/premios`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data.length);
        if (response.data.length < 0) {
        } else {
          response.data.map((d) => {
            var df = JSON.parse(d.style);
            d.style = df;
          });

          setData(response.data);
          setEspera(true);
        }

        //console.log(response);
      });
  }, []);

  return (
    <>
      {!mostrar ? (
        <>
          {espera ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <Wheel
                    radiusLineColor="#ccc"
                    outerBorderColor="#ccc"
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    onStopSpinning={(data2) => {
                      console.log(props);

                      setMustSpin(false);
                      setMostrar(true);

                      fetch(
                        `https://server.lemandolosdelamoto.com/items/landing/${props.res.id}`,
                        {
                          method: "PATCH",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            estado: true,
                            premio: data[prizeNumber].option,
                          }),
                        }
                      )
                        .then((response) => response.json())
                        .then((response) => {
                          if (response.data.id < 0) {
                          } else {
                            console.log("Guardado Correctamente");
                          }

                          console.log(response);
                        });
                    }}
                  />
                  <div style={{ margin: "0 auto", textAlign: "center" }}>
                    <Button
                      style={{ margin: "0 auto", textAlign: "center" }}
                      onClick={handleSpinClick}
                      variant="contained"
                    >
                      Girar y Ganar
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </>
          ) : (
            "Cargando Ruleta..."
          )}
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <p>Has Ganado: {data[prizeNumber].option} </p>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      )}
    </>
  );
};

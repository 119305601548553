import React, { useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ReactPlayer from "react-player";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./App.css";
import { Container, NativeSelect, Typography } from "@mui/material";
import Ruleta from "./Ruleta";

const CssTextField = styled(TextField)({
  "label + &": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important", // override inline-style
  },
});
const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "red",
  "&:hover": {
    backgroundColor: "rgb(182, 5, 5)",
  },
}));
export default function BasicButtons() {
  const [documento, setDocumento] = React.useState("");
  const [nombres, setNombres] = useState("");
  const [tipo, setTipos] = useState("Seleccionar");
  const [respuestatipo, setRespuestatipo] = useState("");
  const [placa, setPlaca] = useState("");
  const [celular, setCelular] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [correo, setCorreo] = useState("");
  const [ruleta, setRuleta] = useState(false);
  const [tratamiento, setTratamiento] = useState(false);
  const [respuesta, setRes] = useState({});

  const EnviarForm = () => {
    var valid = 0;
    if (tipo == "Seleccionar") {
      valid = 1;
      setRespuestatipo("Debe seleccionar un tipo de vehiculo");
    }
    if (nombres == "") {
      valid = 1;
      setRespuestatipo("Nombre no puede estar vacio");
    }

    if (documento == "") {
      valid = 1;
      setRespuestatipo("Documento no puede estar vacio");
    }
    if (placa == "") {
      valid = 1;
      setRespuestatipo("Placa no puede estar vacio");
    }

    if (celular == "") {
      valid = 1;
      setRespuestatipo("Celular no puede estar vacio");
    }

    if (ciudad == "") {
      valid = 1;
      setRespuestatipo("Ciudad no puede estar vacio");
    }

    if (tratamiento == false) {
      valid = 1;
      setRespuestatipo("Debe aceptar el tratamiento de datos personales.");
    }

    if (valid == 0) {
      setRespuestatipo("");
      fetch(`https://server.lemandolosdelamoto.com/items/landing`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombres: nombres,
          documento: documento,
          tipo: tipo,
          placa: placa,
          celular: celular,
          ciudad: ciudad,
          correo: correo,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.data.id < 0) {
          } else {
            console.log("ruleta");
            setRuleta(true);
            setRes(response.data);
          }
          console.log(response.data);
        });
    }
  };
  const vidRef = useRef();

  useEffect(() => {
    setTimeout(function () {
      vidRef.current.muted = false;
    }, 3000);

    //vidRef.current.muted = false;
  }, []);
  return (
    <>
      {ruleta ? (
        <Ruleta res={respuesta} />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <h1 className="titulo uno">A CAMBIAR ESTAS</h1>
          <h1 className="titulo dos">CIFRAS ESCANDALOSAS</h1>
          <Grid container spacing={2}>
            <Grid md={2} xs={0}></Grid>
            <Grid md={8} xs={12}>
              <iframe
                width={"100%"}
                height="450"
                className="iframe"
                src="https://www.youtube.com/embed/vm0RJLVEWmA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Grid>
            <Grid md={2} xs={0}></Grid>
          </Grid>
          <Container>
            <h3 className="titulo tres">SUMATE A LA INICIATIVA E INCRIBETE</h3>
            <p className="text-amarillo">
              Registra tus datos vamos a crear una comunidad de soluciones en
              transporte
            </p>
            <p className="text-amarillo">
              Al dar tus datos para ser parte de la comunidad, aceptas el
              tratamiento de los mismos
            </p>
            <p className="text-amarillo">
              Estarás en el calendario de alertas y recibirás mensajes
              preventivos cuando se vaya a vencer tu Revisión Técnico mecánica.
            </p>

            <br />

            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <h1 style={{ textAlign: "center" }}>
                  ¿Las Cifras dadas del gobierno son ciertas?
                </h1>
              </Grid>
              <Grid xs={12} md={4}>
                <label htmlFor="cars">Nombre y apellido:</label>
                <input
                  className="selects"
                  onChange={(text) => {
                    setNombres(text.target.value);
                    console.log(text.target.value);
                  }}
                  size="small"
                  label="Nombre y apellido"
                  id="custom-css-outlined-input"
                />

                <label htmlFor="cars">Documento de indentidad:</label>
                <input
                  type={"number"}
                  className="selects"
                  size="small"
                  onChange={(text) => {
                    setDocumento(text.target.value);
                    console.log(text.target.value);
                  }}
                  label="Documento"
                  id="custom-css-outlined-input"
                />

                <label htmlFor="cars">Carro o moto:</label>
                <select
                  required
                  onChange={(text) => {
                    setTipos(text.target.value);
                    console.log(text.target.value);
                  }}
                  name="cars"
                  className="selects"
                >
                  <option>Selecionar</option>
                  <option value={"Moto"}>Moto</option>
                  <option value={"Carro"}>Carro</option>
                </select>

                <label htmlFor="cars">Placa</label>
                <input
                  className="selects"
                  onChange={(text) => {
                    setPlaca(text.target.value);
                    console.log(text.target.value);
                  }}
                  size="small"
                  label="Placa"
                  id="custom-css-outlined-input"
                />

                <label htmlFor="cars">Correo</label>
                <input
                  className="selects"
                  onChange={(text) => {
                    setCorreo(text.target.value);
                    console.log(text.target.value);
                  }}
                  size="small"
                  label="Placa"
                  id="custom-css-outlined-input"
                />

                <label htmlFor="cars">Celular:</label>
                <input
                  className="selects"
                  onChange={(text) => {
                    setCelular(text.target.value);
                    console.log(text.target.value);
                  }}
                  size="small"
                  label="Celular"
                  id="custom-css-outlined-input"
                />

                <label htmlFor="cars">Ciudad o municipio:</label>
                <input
                  className="selects"
                  onChange={(text) => {
                    setCiudad(text.target.value);
                    console.log(text.target.value);
                  }}
                  size="small"
                  label="Ciudad o municipio"
                  id="custom-css-outlined-input"
                />
                <label>
                  <input
                    onChange={(text) => {
                      setTratamiento(!tratamiento);
                      console.log(tratamiento);
                    }}
                    type="checkbox"
                    id="cbox1"
                    value="first_checkbox"
                  />
                  Acepto el tratamiento de mis datos personales{" "}
                </label>
                <a
                  style={{ fontSize: 12 }}
                  target="_blank"
                  href="./tratamiento-de-datos.pdf"
                >
                  Tratamiento de datos personales
                </a>
                <p style={{ fontSize: 14, color: "red" }}> {respuestatipo}</p>
                <ColorButton
                  onClick={EnviarForm}
                  className="boton"
                  size="large"
                  variant="contained"
                >
                  Enviar
                </ColorButton>
              </Grid>

              <Grid xs={12} md={4}>
                <h3 style={{ textAlign: "center" }}>
                  ¿Los Motociclistas somos realmente los que más evadimos la
                  RTM?
                </h3>
                <h3 style={{ textAlign: "center" }}>
                  ¿Es verdad que somos evasores?
                </h3>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <h3 style={{ textAlign: "center" }}>
                  ¿De verdad somos los malos de este discurso de evasion?
                </h3>
              </Grid>
              <Grid xs={12} md={4}>
                <h3 style={{ textAlign: "center" }}>
                  ¿Usted transita en su vehiculo sin documentos?
                </h3>
              </Grid>

              <Grid xs={12} md={4}>
                <h3 style={{ textAlign: "center" }}>
                  ¿Es verdad de que no tenemos RTM ni SOAT?
                </h3>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <h3 style={{ margin: "0 auto", textAlign: "center" }}>
                Vamos a hacer un cambio de estas cifras por eso requerimos tus
                datos completos así sabemos quienes somos parte de la solución.
              </h3>
              <p
                style={{ fontSize: 15, margin: "0 auto", textAlign: "center" }}
              >
                Es muy importante para ustedes y para mi, organizarnos y lograr
                que la mayor cantidad de
              </p>
              <p
                style={{ fontSize: 15, margin: "0 auto", textAlign: "center" }}
              >
                conductores esten preparados con la mejor información con
                soluciones rapidas y claras que no
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  margin: "0 auto",
                  width: "100%",
                }}
              >
                nos cueste mas, que no nos roben.
              </p>
              <br />
              <p
                style={{ fontSize: 22, margin: "0 auto", textAlign: "center" }}
              >
                Invita a tus amigos dueños o conductores. Agrégate a la base de
                datos y ‘ruletea’ tu premio!
              </p>
              <h3
                style={{
                  width: "100%",
                  textAlign: "center",
                  background: "rgb(18, 41, 104)",
                  textAlign: "center",
                }}
              >
                SUMATE A LA INICIATIVA E INSCRIBETE
              </h3>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
